import { useEffect } from "react";
import axios, { InternalAxiosRequestConfig } from "axios";
import { BASE_API_URL } from "../constants";
import UserService from "../services/user.service";

export function useAxiosInstance() {
  const _axios = axios.create({
    baseURL: BASE_API_URL,
    headers: {
      "user-country": UserService.getUserCountry() || "n/a",
    },
  });

  useEffect(() => {
    _axios.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        if (UserService.isLoggedIn()) {
          try {
            config.headers.Authorization = `Bearer ${UserService.getToken()}`;
            return config;
          } catch (error) {
            return Promise.reject(error);
          }
        } else return config;
      }
    );
  }, [_axios.interceptors.request]);

  return _axios;
}
